import React, { useState } from 'react' 
import PropTypes from "prop-types"
import Layout from "../components/Layout/Layout"
 import Video from '../components/UI/Video/Video' 
import Link from '../components/UI/Link/Link'
import Typography from '../components/UI/Typography/Typography'
import Image from "../components/UI/Image/Image"
import {videoSliderStyles} from '../components/VideoTabsLayout/VideoTabsLayout-tw-styles'
import Slider from "react-slick";
import { imageConstants } from "../constants/image.constants"
import Button from '../components/UI/Button/Button'
import { labels } from '../constants/label.constants'
import DynamicTable from '../components/UI/DynamicTable/DynamicTable'

const VideoDetails = (props) => {
  const { pageContext } = props
  const { contentfulData, slug,seoData, globalComponents, details, videoList } = pageContext

  const {
    title,
    thumbnailImage,
    description,
    youtubeVideoId,
    relatedVideosIDsList,
    recipeDetail
  } = details || {}

  const [viewRecipe, setViewRecipe] = useState(false)
  const headerForNutritionTable = ["Typical Values", "Per Serving"]

  const toggleViewRecipe = () => {
    setViewRecipe(!viewRecipe)
  }
  
  contentfulData?.filter(Boolean).map(pageData => {
    if (
      slug?.toLowerCase().trim() ==
      deslashify(pageData?.slug?.toLowerCase().trim())
      ) {
        pageWiseContentfuldata = pageData
      }
  })
  
  const PrevArrow = ({ onClick }) => {
    return (
      <button
      className="slick-prev absolute left-[20px] xl:left-[-40px] bottom-[-40px] xl:top-[45%] xl:bottom-auto icon-left-arrow before:text-[27px] xl:before:text-[55px]"
      onClick={onClick}>
    </button>
    );
  }

  const NextArrow = ({ onClick }) => {
    return (
      <button
      className="slick-next absolute right-[20px] xl:right-[-40px] bottom-[-40px] xl:top-[45%] xl:bottom-auto icon-right-arrow before:text-[27px] xl:before:text-[55px]"
      onClick={onClick}>
    </button>
    );
  }

  const videoSliderComponent = slides => {
    const { sliderWrpr, sliderImg } = videoSliderStyles
    let gridClass = (slides?.length < 3) ? "lg:grid lg:grid-cols-2" : "";

    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: (slides?.length >= 3) ? 4 : slides?.length,
      slidesToScroll: 1,
      nextArrow: <NextArrow/>,
      prevArrow: <PrevArrow/>,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };

    return (
      <div className={`recipe-slider py-15 justify-center align-center mx-auto xl:w-[1200px] mb-30 ${gridClass} relative`}>
        <Slider {...settings}>
          {slides?.map((slide, index) => {
            return (             
              <div key={index} className={sliderWrpr}>
                <div className='relative'>
                <Link url={`/the-philly-academy/${slide.slug.url}/`} key={index}>
                  <Image
                    src={slide.thumbnailImage.url}
                    alt={slide.title}
                    className={sliderImg}
                  />
                  <div className='relative flex justify-center'>
                  {slide.icon && (
                    <Image className={videoSliderStyles?.icon} contentfulImage={slide.icon} />
                  )}
                  </div>
                  {slide.title && (
                    <Typography
                      className={videoSliderStyles?.videoHeading}
                      content={slide.title}
                    />
                  )}
                  <span className={videoSliderStyles?.gradTop}></span>
                </Link>
                </div>
              </div>     
                    
            )
          })}
        </Slider>
      </div>
    );
  }
  
  return (
    <Layout seoData={seoData} globalComponents={globalComponents}>
      <section className="bg-lightGray text-darkBlue text-center text-[45px] p-40 w-full font-PhiladelphiaBold  lg:mx-auto px-15 [&>h2]:mb-0 [&>h2]:leading-[49px] tracking-wide:leading-[50px]">
        <h2>{title}</h2>
      </section>
      
      <section className="aVContainer  max-w-full xl:w-[1170px] w-full lg:mx-auto flex flex-wrap justify-center text-center my-50">
        <div className=" max-w-full xl:w-[1170px] w-full flex flex-wrap xl:flex-nowrap justify-between items-center">
          <div className="w-full lg:w-1/3 flex justify-center">
            <Video
              youtubeVideoId={youtubeVideoId}
              posterImage={thumbnailImage}
              isModal={false}
              title={title}
              style={{
                videoframe: " px-10 lg:px-0",
                aVContainer:
                  "aVContainer max-w-full lg:w-[1170px] w-full lg:mx-auto flex flex-wrap justify-center text-center my-50 max-w-full flex flex-col-reverse",
                contentReversed: "flex flex-col-reverse",
                aVcontentHeading:
                  "aVcontentHeading text-darkBlue text-[40px] mt-50 mb-20 flex justify-center",
                aVDescription: "aVDescription rwast ",
                aVcontent: "videoText",
                description:
                  "description text-40 mb-20 lg:mb-40 px-20 lg:px-auto [&>h3]:leading-[50px] [&>h2]:leading-[50px]",
                videoItem:
                  "md:min-h-[398px] lg:min-h-[622px] min-h-[196px] w-[350px] lg:rounded-[30px] rounded-[20px] max-w-full",
                videoCntr:
                  "videocontainer relative lg:min-h-[405px] max-w-full",
              }}
            />
          </div>
     
          <div className="w-full lg:w-1/3 flex justify-between flex-col relative my-20 lg:my-0">
            <Typography
              content={description?.description}
              className={
                "video-description leading-[28px] text-[20px] lg:text-24 pb-0 text-bg-darkBlue text-center px-10 xl:px-20 mb-10"
              }
            />
            {recipeDetail && (
              <>
                <div className='btn-section hidden lg:block mt-10'>
                  <Button
                    text={viewRecipe?labels.hideTheRecipe:labels.viewTheRecipe}
                    className="text-sm buttonEffect2 recipeBtn font-ProxBolder"
                    dataActionDetail={viewRecipe?labels.hideTheRecipe:labels.viewTheRecipe}
                    onClick={toggleViewRecipe}
                  />
                </div>
              </>
            )}
          </div>

          <div className="w-full lg:w-1/3 flex justify-between flex-col relative h-auto lg:h-full z-0 px-10 xl:px-0">
            <Image
              src={"https://images.ctfassets.net/qc3lz96t0r4x/5d1T8xzBe3W4KDBryRypNY/1fb0191a8bf96f5398c1c830391955ce/Frame_4_video.png"}
              className={"xl:h-[360px] xl:w-[360px] xl:rotate-[-20deg] xl:absolute top-0 right-0 z-10"}
            />
            <Image
              src={"https://images.ctfassets.net/qc3lz96t0r4x/5yFv3gWtIWRFHOYC25OYqy/2984c266ef7bd36872881a1d178ecddc/Frame_3_video.png"}
              className={"xl:h-[360px] xl:w-[360px] xl:rotate-[25deg] xl:absolute right-0 top-[30%]"}
            />
          </div>
        </div> 
        {recipeDetail && (
          <>
            <div className='btn-section block lg:hidden mt-10'>
              <Button
                text={viewRecipe?labels.hideTheRecipe:labels.viewTheRecipe}
                className="text-sm buttonEffect2 recipeBtn font-ProxBolder"
                dataActionDetail={viewRecipe?labels.hideTheRecipe:labels.viewTheRecipe}
                onClick={toggleViewRecipe}
              />
            </div>
          </>
        )}
      </section>
      

      {viewRecipe && <section className='recipe-details mx-auto md:flex items-start gap-20 mt-[28px] mb-[50px] justify-between w-full max-w-[1170px] p-8 md:px-30 lg:px-0 printwidth'>
        <div className='w-full lg:max-w-[70%]'>
          {recipeDetail?.RecipeDescription && <Typography className="md:text-3xl md:leading-[40px] font-ProxBold text-[25px] md:text-left text-center" content={recipeDetail?.RecipeDescription} />}
          <div className='grid grid-cols-3 justify-center gap-20 md:w-[379px] w-[300px] py-20 text-sm md:mx-0 mx-auto'>
            {recipeDetail?.Portions && <div className='portion prepTime font-ProxBold child:font-ProxRegular child:text-15'>
              {recipeDetail?.Portions}
              <div>{labels?.servings}</div>
            </div>}
            {recipeDetail?.PrepTime && <div className='portion prepTime font-ProxBold child:font-ProxRegular child:text-15'>
              {recipeDetail?.PrepTime}
              <div>{labels?.prepTime}</div>
            </div>}
            {recipeDetail?.CookTime && <div className='portion prepTime font-ProxBold child:font-ProxRegular child:text-15'>
              {recipeDetail?.CookTime}
              <div>{labels?.totalTime}</div>
            </div>}
          </div>
        
          {recipeDetail?.Method && <div>
            <div className='md:text-3xl mt-15 md:leading-[40px] font-ProxBold text-[25px] md:text-left text-center'>{labels.method}</div>
            <div className="flex w-full method-listing">
              <Typography content={recipeDetail?.Method} />
            </div>
          </div>}

          {recipeDetail?.ChefNotes &&
            <div className='mt-50 font-ProxBold'>
              <Typography content={recipeDetail?.ChefNotes} />
            </div>}

        </div>
        <div className='w-full lg:max-w-[30%] mt-25 lg:mt-0'>
          <div className='recipe-box px-25 py-15 bg-darkBlue rounded-lg'>
            {recipeDetail?.Ingredients && <div>
              <div className='md:text-2xl text-white font-ProxBold text-[18px] mb-15 md:text-left text-center'>{labels.recipeIngredients}</div>
              <div className="flex w-full text-white">
                <Typography content={recipeDetail?.Ingredients} />
              </div>
            </div>}
          </div>
          
          
          {recipeDetail?.NutritionalTable && (
            <DynamicTable
              rowData={recipeDetail?.NutritionalTable}
              showHeading={true}
              heading={labels.nutritionalInformation}
              rowHeading={headerForNutritionTable}
              variant={"newtable"}
            />
          )}
        </div>
        
      </section>}

      <section className="bg-darkBlue text-white text-center text-[40px] lg:text-[50px] p-45 w-full font-PhiladelphiaBold lg:mx-auto px-15 [&>h2]:leading-[49px] [&>h2]:m-0 tracking-wide:leading-[50px]">
        <h2>Check out more hacks...</h2>
      </section>

      <section>
          {videoSliderComponent(videoList)}
      </section>
    </Layout>
  )
}

VideoDetails.propTypes = {
  data: PropTypes.any,
  pageContext: PropTypes.shape({
    contentfulData: PropTypes.array,
    slug: PropTypes.string,
    globalComponents: PropTypes.array,
    details: PropTypes.object,
    seoData: PropTypes.object,
    videoList: PropTypes.array,
    onClick: PropTypes.func,
  }),
}

export default VideoDetails;

 
    
    