import React, { useState } from "react"
import PropTypes from "prop-types"
import Image from "../Image/Image"
import { imageConstants } from "../../../constants/image.constants"
import VideoModal from "../../ArticleVideo/VideoModal/VideoModal"
import Button from "../Button/Button"

const ModalView = props => {
  const { poster, videoLink, isIframe, title } = props
  const [isModalOpen, setIsModalOpen] = useState(false)
  return (
    <>
      <Button
        onClick={() => {
          setIsModalOpen(true)
        }}
      >
        <Image src={poster.url} className={"rounded-30"} />
        <Image
          contentfulImage={imageConstants.playBtn}
          className={"bg-lightGray absolute w-[40px] h-[40px] md:w-[72px] md:h-[72px] left-[50%] top-[50%] ml-[-20px] mt-[-20px] md:ml-[-36px] md:mt-[-36px]"}
        />
      </Button>

      {isModalOpen && (
        <VideoModal
          isModalOpen={isModalOpen}
          closeVideoModal={() => {
            setIsModalOpen(false)
          }}
          isIframe={isIframe}
          src={videoLink}
          title={title}
          isModal={true}
          closeModalImage={imageConstants.close}
        />
      )}
    </>
  )
}

ModalView.propTypes = {
  poster: PropTypes.string,
  videoLink: PropTypes.string,
  isIframe: PropTypes.bool,
  title: PropTypes.string,
}

export default ModalView
