import React from "react"
import PropTypes from "prop-types"
import InlineView from "./InlineView"
import ModalView from "./ModalView"

const Video = props => {
  const {
    sitecoreVideo,
    youtubeVideoId,
    posterImage,
    style,
    isModal,
    title,
    isImageToggle,
  } = props
  const isIframe = !!youtubeVideoId

  function getVideoLink() {
    if (isIframe) {
      return `https://www.youtube-nocookie.com/embed/${youtubeVideoId}?enablejsapi=1`
    }
    return sitecoreVideo ? sitecoreVideo.url : null
  }
  const videoLink = getVideoLink()

  return (
    <div>
      {isModal ? (
        <ModalView
          poster={posterImage}
          style={style}
          isIframe={isIframe}
          videoLink={videoLink}
          title={title}
        />
      ) : (
        <InlineView
          poster={posterImage}
          style={style}
          isIframe={isIframe}
          videoLink={videoLink}
          title={title}
          videoId={youtubeVideoId}
          isImageToggle={isImageToggle}
        />
      )}
    </div>
  )
}

Video.propTypes = {
  sitecoreVideo: PropTypes.object,
  youtubeVideoId: PropTypes.string,
  posterImage: PropTypes.object,
  style: PropTypes.object,
  isModal: PropTypes.bool,
  title: PropTypes.string,
  isImageToggle:PropTypes.bool
}

export default Video
